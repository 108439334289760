import { createRouter , createWebHistory  } from "vue-router";
import indexPage from '../views/indexPage.vue'
// import vuePage from '../App.vue'
import studyCodePage from '@/views/studyCodePage.vue'
import sourcePage from "@/views/sourcePage.vue";
import pathPage from '../views/pathStudyPage.vue'
import defaultPage from '../views/coursePage.vue'
import coursePage from '../views/coursePage.vue'
import studyDoc from "../views/studyDoc.vue"
import superLogin from "../views/superLoginPage.vue"
import superPage from "../views/superPage.vue"
const routes=[
  {
      path:'/',
      redirect:{
        name:'indexPage'
      }
  },
  {
    path:'',
    name:'indexPage',
    component:indexPage,
    children:[
      {
        path:'/source',
        name:'sourcePage',
        component:sourcePage
      },

      {
        path:'',
        name:'defaultPage',
        component:defaultPage
      },
            {
        path:'/course',
        name:'coursePage',
        component:coursePage
      },
      {
        path:'/study/:videoUrl',
        name:'studyCodePage',
        component:studyCodePage
      },
      {
        path:'/study_path',
        name:'pathStudyPage',
        component:pathPage
      },
      {
        path:'/study_doc',
        name:'studyDoc',
        component:studyDoc
      },
      {
        path:'/superLogin',
        component:superLogin
      },{
        path:'super',
        component:superPage
      }
    ]
  }

]

const router=createRouter({
  history:createWebHistory(),
  routes
})

export default router