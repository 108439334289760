<template>
  <div v-for="(itemFileBlock, index) in fileList " :key="index">
    <div class="flex-row " style="justify-content: space-between;">
      <h2>{{ itemFileBlock.category_name }}</h2>
      <h4>来源：{{ itemFileBlock.srcFrom }}</h4>
    </div>
    <a-list>
      <a-list-item v-for="(item, idx) in itemFileBlock.theFileList" :key="idx" class="file-item">
        <a-list-item-meta :title="item.title">
        </a-list-item-meta>
        <template #actions>
          <fileIcon style="height: 20px; width: 20px;" @click="handleDownload(item)"></fileIcon>
        </template>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import fileIcon from './Icon/fileIcon.vue';
export default {
  components: {
    fileIcon,
  },
  data() {
    return {
      fileList: [
        {
          category_name: "HTML+CSS",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'html基础'
            },
            {
              title: 'CSS01-基础选择器+字体文本样式'
            }
          ]
        },
        {
          category_name: "前端JavaScript",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'js课程阶段说明'
            },
            {
              title: 'webAPIs第一天'
            },
            {
              title: 'webAPIs第二天'
            },
            {
              title: 'webAPIs第三天'
            }
          ]
        },
        {
          category_name: "vue2+3资料",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: '快速上手+插值表达式+指令上'
            },
            {
              title: '指令下+计算属性+侦听器'
            }
          ]
        },
        {
          category_name: "React基础",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'React基础01'
            },
            {
              title: 'React基础02'
            }
          ]
        },

      ]
    }
  },
  methods: {
    handleDownload(item) {
      window.open("https://studynest.top/file/" + item.title + ".pdf", "_blank")
    }
  }
}
</script>

<style scoped>
.file-item:hover {
  background-color: rgb(209, 209, 209);
}
</style>