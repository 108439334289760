<template>
  <start-page @start-change="handleStart"></start-page>
  <router-view></router-view>
  <a-modal v-model:visible="visible" @ok="hanldeOK" draggable>
    <template #title>
      <strong>资源说明</strong>
    </template>
    <div>
      <strong>尊敬的网站访问者：</strong><br>
      &nbsp; &nbsp; 您好！<br>
      &nbsp; &nbsp;感谢您对我们网站的访问与支持。为了给您提供丰富多样的内容体验，我们在网站上嵌入了哔哩哔哩（B站）的视频播放功能。在此，我们特此公告，提醒您关注以下视频版权问题：<br>
      <ul>
        <li>1、本网站嵌入的B站视频，<strong>其版权均归原作者所有</strong>。我们尊重知识产权，坚决反对任何形式的侵权行为。</li>
        <li>2、视频播放过程中，请遵守相关法律法规，不得进行非法下载、传播、篡改等侵犯版权的行为。</li>
        <li>3、如有版权方认为本网站嵌入的视频存在侵权行为，请及时与我们联系，我们将尽快核实并采取措施予以处理。</li>
        <li>4、用户在观看视频时，若发现视频存在版权问题，请勿进行传播，并积极向本网站反馈，我们将予以高度重视。</li>
      </ul>
      &nbsp; &nbsp; 请您自觉遵守以上规定，共同维护网络版权环境。如有疑问，请联系我们的客服人员，我们将竭诚为您解答。<br>
      &nbsp; &nbsp; 感谢您的理解与支持，祝您学习愉快！
      <div style="display: flex; flex-direction: row; justify-content: end; font-weight: 600;">站长</div>
    </div>

  </a-modal>
</template>

<script>
import startPage from './components/startPage.vue';
export default {
  name: 'App',
  components: {
    startPage
  },
  data() {
    return {
      visible: false
    }

  },
  methods: {
    hanldeOK() {
      this.visible = false
      this.$notification.info({
        title: "学习提醒~",
        content: "点击你想学的课程，即可进行别看边学哦！",
        duration: 15000,
        closable: true
      })
    },
    handleStart(value) {
      this.visible = value
    }
  }
}

</script>

<style>
a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.mar-10px {
  margin: 10px;
}

.pad-5px {
  padding: 5px;
}

.pad-10px {
  padding: 10px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-items-center {
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.title-text {
  font-size: 26px;
  font-weight: 600;
  margin: 20px;
}

.info-text {
  font-size: 22px;
  font-weight: 400;
  margin: 10px;
}

.menu-text {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.menu-text:hover {
  cursor: pointer;
  background-color: gray;
}

.pad-5px {
  padding: 5px;
}

.flex-align-center {
  align-items: center;
}

.mar-l-40 {
  margin-left: 40px;
}
</style>
