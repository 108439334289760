<template>
  <div class="menu-demo">
    <a-menu mode="horizontal" :default-selected-keys="['1']" @menu-item-click="shiftMenu">
      <a-menu-item key="1">视频管理</a-menu-item>
      <a-menu-item key="2">资源管理</a-menu-item>
      <a-menu-item key="3">学习路线管理</a-menu-item>
    </a-menu>
  </div>
  <div class="flex-row" style="justify-content:right; margin-right: 3vw; margin-bottom: 2vh;">
    <a-button type="primary" @click="addItem">添加</a-button>
  </div>

  <div class="show-box">
    <a-list v-if="choose === '1'">
      <a-list-item v-for="(item, index) in courseList" :key="index">
        <a-list-item-meta :title="item.title" :description="item.description">
        </a-list-item-meta>
        <template #actions>
          <icon-edit @click="editVideo" />
          <icon-delete @click="deleteFileItem" />
        </template>
      </a-list-item>
    </a-list>
    <div v-if="choose === '2'">
      <div v-for="(item, index) in fileList" :key="index">
        <div class="title-box flex-row ">
          <span>{{ item.category_name }}</span>
          <span class="mar-l-40">{{ item.srcFrom }}</span>
          <icon-edit class="mar-l-40" @click="editFileInfo" />
          <icon-delete class="mar-l-40" @click="deleteFiles" />
        </div>
        <a-list>
          <a-list-item v-for="(i, idx) in item.theFileList" :key="idx">
            <a-list-item-meta :title="i.title">
            </a-list-item-meta>
            <template #actions>
              <icon-edit @click="editFile" />
              <icon-delete @click="deleteFileItem" />
            </template>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="showFileInfoForm" title="修改资源归属信息" @cancel="handleCancel" @ok="handleeOkChangeFileInfo">
    <a-form :model="sourceInfo" :style="{ width: '400px' }" auto-label-width>
      <a-form-item field="category_name" label="资源名称">
        <a-input v-model="sourceInfo.category_name" placeholder="输入资源名称" />
      </a-form-item>
      <a-form-item field="srcFrom" label="来自">
        <a-input v-model="sourceInfo.srcFrom" placeholder="输入资源来源" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="msg_visible" @ok="handleOk" @cancel="handleCancel">
    <template #title>
      提示
    </template>
    <div>由于是静态页面，所以你的修改将不会生效</div>
  </a-modal>
  <a-modal v-model:visible="showFileItemForm" title="资源信息" @cancel="handleCancel" @ok="handleeOkChangeFileItemInfo">
    <a-form :model="srcItemForm" :style="{ width: '400px' }" auto-label-width>
      <a-form-item field="title" label="文件名称">
        <a-input v-model="srcItemForm.title" placeholder="输入资源名称" />
      </a-form-item>
      <a-form-item field="file">
        <a-upload action="/" style="margin-top: 40px;" />
      </a-form-item>
      <span style="color: red;">{{ info }}</span>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="showVideoInfoForm" title="视频信息" @cancel="handleCancel" @ok="handleeOkChangeVideoInfo">
    <a-form :model="videoForm" :style="{ width: '400px' }" auto-label-width>
      <a-form-item field="title" label="视频名称">
        <a-input v-model="videoForm.title" placeholder="输入视频名称" />
      </a-form-item>
      <a-form-item field="url" label="来自">
        <a-input v-model="videoForm.url" placeholder="输入视频链接" />
      </a-form-item>
      <a-form-item field="img">
        <a-upload action="/" style="margin-top: 40px;" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="no_open" @ok="handleOk" @cancel="handleCancel">
    <template #title>
      提示
    </template>
    <div>该页面功能还未开发</div>
  </a-modal>
</template>

<script>

export default {
  data() {
    return {
      no_open: false,
      videoForm: {
        title: '',
        url: '',
        img: ''
      },
      info: "请确保文件名称与上传的文件名称一致（不需要后缀如.pdf）",
      showVideoInfoForm: false,
      showFileItemForm: false,
      showFileInfoForm: false,
      msg_visible: false,
      srcItemForm: {
        title: '',
        file: null
      },
      sourceInfo: {
        category_name: "",
        srcFrom: "",
      },
      courseList: [
        {
          title: "html5 +css3+前端项目视频教程",
          description: "前端开发入门教程，web前端零基础html5 +css3+前端项目视频教程",
          img: "/HTML+CSS.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1Kg411T7t9?t=0.6"
        },
        {
          title: "JavaScript入门到精通全套视频教程",
          description: "黑马程序员前端JavaScript入门到精通全套视频教程，javascript核心进阶ES6语法、API、js高级等基础知识和实战教程",
          img: "/JS.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1Y84y1L7Nn?t=1.0"
        },
        {
          title: "Vue2+Vue3基础入门到实战项目全套教程",
          description: "前端最新Vue2+Vue3基础入门到实战项目全套教程，自学前端vue就选黑马程序员，一套全通关！",
          img: "/Vue.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1HV4y1a7n4?t=0.8"
        },
        {
          title: "React18入门到实战视频教程",
          description: "黑马程序员前端React18入门到实战视频教程，从react+hooks核心基础到企业级项目开发实战（B站评论、极客园项目等）及大厂面试全通关",
          img: "/React.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1ZB4y1Z7o8?t=45.7"
        }
      ],
      fileList: [
        {
          category_name: "HTML+CSS",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'html基础'
            },
            {
              title: 'CSS01-基础选择器+字体文本样式'
            }
          ]
        },
        {
          category_name: "前端JavaScript",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'js课程阶段说明'
            },
            {
              title: 'webAPIs第一天'
            },
            {
              title: 'webAPIs第二天'
            },
            {
              title: 'webAPIs第三天'
            }
          ]
        },
        {
          category_name: "vue2+3资料",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: '快速上手+插值表达式+指令上'
            },
            {
              title: '指令下+计算属性+侦听器'
            }
          ]
        },
        {
          category_name: "React基础",
          srcFrom: "黑马程序员",
          theFileList: [
            {
              title: 'React基础01'
            },
            {
              title: 'React基础02'
            }
          ]
        },

      ],

      choose: '1',
    }
  },
  methods: {
    editFile() {
      this.showFileItemForm = true
    },
    handleeOkChangeFileItemInfo() {
      this.msg_visible = true
    },
    shiftMenu(e) {
      this.choose = e

    },
    handleeOkChangeFileInfo() {
      console.log(this.sourceInfo)
      this.msg_visible = true
    },
    handleeOkChangeVideoInfo() {
      this.msg_visible = true
    },
    editFileInfo() {
      this.showFileInfoForm = true
    },
    deleteFiles() {
      this.msg_visible = true
    },
    deleteFileItem() {
      this.msg_visible = true
    },
    editVideo() {
      this.showVideoInfoForm = true
    },
    addItem() {
      if (this.choose === '1') {
        this.showVideoInfoForm = true

      }
      else if (this.choose === '2') {
        this.showFileItemForm = true
      }
      else if (this.choose === '3') {
        this.no_open = true
      }
    }
  }
}
</script>

<style scoped>
.title-box {
  font-size: large;
  margin: 5px;
}

.menu-demo {
  box-sizing: border-box;
  width: 100%;
  padding: 40px;
  background-color: var(--color-neutral-2);
}

.show-box {
  width: 83vw;
  margin-left: 2.3vw;
}
</style>